<template>
  <form class="card auth-card" @submit.prevent="sendForm">
    <div class="card-content">
      <span class="card-title">{{$t('authBlock.WellcomeTo',{name:'DY-CRM'})}}</span>
      <div class="input-field">
        <input
          id="email"
          type="text"
          v-model.trim="email"
          :class="{invalid: ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}"
        >
        <label for="email">{{$t('authBlock.Email')}}</label>
        <small
          class="helper-text invalid"
          v-if="($v.email.$dirty && !$v.email.required)"
        >{{$t('authBlock.FieldCouldntBeEmpty')}}</small>
        <small
          class="helper-text invalid"
          v-else-if="($v.email.$dirty && !$v.email.email)"
        >{{$t('authBlock.EmailIncorrect')}}</small>
      </div>
      <div class="input-field">
        <input
          id="password"
          type="password"
          v-model.trim="password"
          :class="{invalid: ($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength)}"
        >
        <label for="password">{{$t('authBlock.Password')}}</label>
        <small
          class="helper-text invalid"
          v-if="($v.password.$dirty && !$v.password.required)"
        >{{$t('authBlock.InputPassword')}}</small>
        <small
          class="helper-text invalid"
          v-else-if="($v.password.$dirty && !$v.password.minLength)"
        >{{$t('authBlock.PasswordContains', {needAmount: $v.password.$params.minLength.min, currentAmount: password.length})}}</small>
      </div>
    </div>
    <div class="card-action">
      <div>
        <button
          class="btn waves-effect waves-light auth-submit"
          type="submit"
        >
          {{$t('authBlock.Enter')}}
          <i class="material-icons right">send</i>
        </button>
      </div>
      <p class="center">
        {{$t('authBlock.ForgotPassword')}}
        <router-link to="/forgot-password">{{$t('authBlock.RestorePassword')}}</router-link>
      </p>
      <p class="center">
        {{$t('authBlock.NotWithUs')}}
        <router-link to="/register">{{$t('authBlock.Register')}}</router-link>
      </p>
    </div>
  </form>
</template>

<script>
import { email, required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: ''
    }
  },
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Login'
    }
  },
  validations: {
    email: { email, required },
    password: { required, minLength: minLength(8) }
  },
  methods: {
    async sendForm () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const formData = {
        email: this.email,
        password: this.password
      }
      try {
        await this.$store.dispatch('login', formData)
        this.$router.push('/')
      } catch (e) {}
    }
  },
  mounted () {
    if (this.$route.query.message) {
      this.$message(`pluginMessages.${this.$route.query.message}`)
    }
  }
}
</script>

<style scoped>

</style>
